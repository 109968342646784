.piscar {
  background: linear-gradient(90deg, #FF5824, #EF3F7A);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  animation: piscar 1.4s infinite;
}

@keyframes piscar {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}